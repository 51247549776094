import { ChevronDown, Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Stack, Typography, Avatar } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { TenantPopover } from "./tenant-popover";
import { useOrganization } from "src/contexts/organization-context";
import { paths } from "src/paths";
export const TenantSwitch = (props) => {
  const popover = usePopover();
  const navigate = useNavigate();
  const {
    currentOrganization,
    organizations,
    setCurrentOrganization,
    setSelectedCalendar,
  } = useOrganization();

  const handleOrganizationChange = (org) => {
    setCurrentOrganization(org);
    setSelectedCalendar(null);
    popover.handleClose();
  };

  const handleSettingsClick = () => {
    navigate(`${paths.dashboard.workspace}?org=${currentOrganization.uuid}`);
  };

  return (
    <Box>
      <Typography
        color="text.secondary"
        sx={{
          mb: 1,
          fontSize: "0.75rem",
          fontWeight: 600,
          textTransform: "uppercase",
          letterSpacing: "0.5px",
        }}
      >
        Workspace
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{
          backgroundColor: "background.paper",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "12px",
          p: 1.5,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
            borderColor: "primary.main",
          },
          transition: "all 0.2s ease-in-out",
          boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1)",
        }}
        {...props}
      >
        {currentOrganization?.image_url ? (
          <Avatar
            src={currentOrganization?.image_url}
            sx={{ width: 32, height: 32 }}
          />
        ) : (
          <Avatar sx={{ width: 32, height: 32 }}>
            {currentOrganization?.name[0]}
          </Avatar>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="inherit" variant="h6">
            {currentOrganization?.name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleSettingsClick}>
            <Settings size={16} />
          </IconButton>
          <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
            <ChevronDown size={16} />
          </IconButton>
        </Box>
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={handleOrganizationChange}
        onClose={popover.handleClose}
        open={popover.open}
        organizations={organizations}
      />
    </Box>
  );
};
