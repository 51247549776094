export const BASE_URL_API =
  process.env.REACT_APP_API_URL || "https://stage.tigest.club/api/v1";

export const BASE_URL_DASHBOARD = `${BASE_URL_API}/dashboard`;

export const settings = {
  colorPreset: "blue",
  contrast: "normal",
  direction: "ltr",
  layout: "vertical",
  navColor: "discreet",
  paletteMode: "light",
  responsiveFontSizes: true,
  stretch: false,
};

export const SOCIAL_NETWORKS = [
  {
    name: "linkedin",
    value: "Linkedin",
    enabled: true,
    url: "linkedin.com",
  },
  {
    name: "telegram",
    value: "Telegram",
    enabled: true,
    url: "telegram.com",
  },
  {
    name: "twitter",
    value: "Twitter",
    enabled: true,
    url: "twitter.com",
  },
  {
    name: "instagram",
    value: "Instagram",
    enabled: false,
    url: "instagram.com",
  },
  {
    name: "facebook",
    value: "Facebook",
    enabled: false,
    url: "facebook.com",
  },
  {
    name: "youtube",
    value: "YouTube",
    enabled: false,
    url: "youtube.com",
  },
  {
    name: "reddit",
    value: "Reddit",
    enabled: false,
    url: "reddit.com",
  },
];
