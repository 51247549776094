import React, { createContext, useState, useContext, useEffect } from "react";
import { getCalendars } from "src/api/calendar";
import { getPosts } from "src/api/post";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";

const OrganizationContext = createContext(undefined);

const LOCAL_STORAGE_ORG_KEY = "selectedOrganization";
const LOCAL_STORAGE_CALENDAR_KEY = "selectedCalendar";

export const OrganizationProvider = ({ children }) => {
  // State declarations
  const [organizations, setOrganizations] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [posts, setPosts] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [addCalendarFromNavigation, setAddCalendarFromNavigation] =
    useState(null);
  const [selectedCalendarSettings, setSelectedCalendarSettings] =
    useState(null);
  const [isCalendarsLoading, setIsCalendarsLoading] = useState(false);
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [hasLoadedCalendars, setHasLoadedCalendars] = useState(false);
  const [hasLoadedPosts, setHasLoadedPosts] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState([]);


  const { user } = useAuth();
  // console.log("user", user);

  // Helper functions
  const clearOrganizationState = () => {
    setOrganizations([]);
    setCurrentOrganization(null);
    setCalendars([]);
    setPosts([]);
    setSelectedCalendar(null);
    localStorage.removeItem(LOCAL_STORAGE_ORG_KEY);
    localStorage.removeItem(LOCAL_STORAGE_CALENDAR_KEY);
  };

  const fetchCalendars = async (orgUuid) => {
    if (!orgUuid) return;

    try {
      if (!hasLoadedCalendars) {
        setIsCalendarsLoading(true);
      }

      const response = await getCalendars(orgUuid);
      let fetchedCalendars = [];
      if (response.success) {
        fetchedCalendars = response.data ?? [];
        setCalendars(response.data ?? []);
      }

      let calendarToSelect = null;

      const storedCalendarUuid = localStorage.getItem(
        LOCAL_STORAGE_CALENDAR_KEY
      );
      if (storedCalendarUuid) {
        calendarToSelect = fetchedCalendars.find(
          (calendar) => calendar?.uuid === storedCalendarUuid
        );
      }

      if (!calendarToSelect && fetchedCalendars.length > 0) {
        calendarToSelect = fetchedCalendars[0];
      }

      setSelectedCalendar(calendarToSelect || null);

      if (calendarToSelect) {
        localStorage.setItem(LOCAL_STORAGE_CALENDAR_KEY, calendarToSelect.uuid);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_CALENDAR_KEY);
      }

      if (!hasLoadedCalendars) {
        setHasLoadedCalendars(true);
      }
    } catch (error) {
      console.error("Error fetching calendars:", error);
      setCalendars([]);
    } finally {
      if (!hasLoadedCalendars) {
        setIsCalendarsLoading(false);
      }
    }
  };

  const fetchPosts = async (calendarUuid) => {
    if (!calendarUuid) return;

    try {
      if (!hasLoadedPosts) {
        setIsPostsLoading(true);
      }

      const fetchedPosts = await getPosts(calendarUuid);
      setPosts(fetchedPosts);
      setAllPosts(fetchedPosts);

      if (!hasLoadedPosts) {
        setHasLoadedPosts(true);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      setPosts([]);
    } finally {
      if (!hasLoadedPosts) {
        setIsPostsLoading(false);
      }
    }
  };


  const fetchPostsForCalendars = async (calendarUuids) => {
    if (!calendarUuids || calendarUuids.length === 0) return;

    try {
      setIsPostsLoading(true);

      const allPosts = [];
      for (const calendarUuid of calendarUuids) {
        const fetchedPosts = await getPosts(calendarUuid);
        allPosts.push(...fetchedPosts);
      }

      setAllPosts(allPosts);
      setPosts(allPosts);

      setHasLoadedPosts(true);
    } catch (error) {
      console.error("Error fetching posts for multiple calendars:", error);
      setAllPosts([]);
      setPosts([]);
    } finally {
      setIsPostsLoading(false);
    }
  };

  const setOrganization = (organization) => {
    setCurrentOrganization(organization);
    localStorage.setItem(LOCAL_STORAGE_ORG_KEY, organization?.uuid || "");
    setSelectedCalendar(null);
    fetchCalendars(organization?.uuid);
  };

  const handleSelectCalendar = (calendar) => {
    setSelectedCalendar(calendar);
    if (calendar) {
      localStorage.setItem(LOCAL_STORAGE_CALENDAR_KEY, calendar.uuid);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CALENDAR_KEY);
    }

    fetchPosts(calendar?.uuid);
  };

  const refreshCalendars = async () => {
    if (currentOrganization?.uuid) {
      await fetchCalendars(currentOrganization.uuid);
    }
  };

  // Effects
  useEffect(() => {
    // Effect for setting initial organization
    if (user && user.organizations?.length) {
      setOrganizations(user.organizations);

      const storedOrgUuid = localStorage.getItem(LOCAL_STORAGE_ORG_KEY);
      let selectedOrg = user.organizations[0];

      if (storedOrgUuid) {
        const matchingOrg = user.organizations.find(
          (org) => org.uuid === storedOrgUuid
        );
        if (matchingOrg) {
          selectedOrg = matchingOrg;
        }
      }

      setOrganization(selectedOrg);
    }
  }, [user]);

  useEffect(() => {
    // Effect for fetching posts when selected calendar changes
    if (selectedCalendar?.uuid) {
      fetchPosts(selectedCalendar.uuid);
      setSelectedCalendars([selectedCalendar?.username]);
    } else {
      setPosts([]);
    }
  }, [selectedCalendar]);

  useEffect(() => {
    // Effect for selecting initial calendar
    if (
      currentOrganization?.uuid &&
      calendars.length > 0 &&
      !selectedCalendar
    ) {
      const storedCalendarUuid = localStorage.getItem(
        LOCAL_STORAGE_CALENDAR_KEY
      );
      let calendarToSelect = null;
      if (storedCalendarUuid) {
        calendarToSelect = calendars.find(
          (calendar) => calendar.uuid === storedCalendarUuid
        );
      }
      if (!calendarToSelect && calendars.length > 0) {
        calendarToSelect = calendars[0];
      }
      setSelectedCalendar(calendarToSelect || null);
      if (calendarToSelect) {
        localStorage.setItem(LOCAL_STORAGE_CALENDAR_KEY, calendarToSelect.uuid);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_CALENDAR_KEY);
      }
    }
  }, [currentOrganization, calendars, selectedCalendar]);

  // Context value
  const value = {
    organizations,
    currentOrganization,
    calendars,
    posts,
    fetchPosts,
    setPosts,
    selectedCalendar,
    setSelectedCalendar: handleSelectCalendar,
    isLoading,
    handleSelectCalendar,
    clearOrganizationState,
    refreshCalendars,
    addCalendarFromNavigation,
    setAddCalendarFromNavigation,
    selectedCalendarSettings,
    setCurrentOrganization: setOrganization,
    setSelectedCalendarSettings,
    isCalendarsLoading,
    isPostsLoading,
    allPosts,
    fetchPostsForCalendars,
    selectedCalendars,
    setSelectedCalendars,
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      "useOrganization must be used within an OrganizationProvider"
    );
  }
  return context;
};
